// TODO: convert to typescript
import PropTypes from 'prop-types'
import React, { useState } from 'react'
import {
  Box,
  Button,
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  Grid,
  InputAdornment,
  Radio,
  RadioGroup,
  Typography,
} from '@mui/material'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { makeStyles } from 'tss-react/mui'

import AddonSelector from './addons'
import Form from '@shared/form'
import FormImageInput from '@shared/form-image-input'
import FormInput from '@shared/form-input'
import FormSwitch from '@shared/form-switch'

import ErrorBoundary from '@components/shared/error-boundary'
import InventoryControlSelect from './inventory-control-select'
import NetsuiteAccountSelect from './netsuite-account-select'
import PromotionalCategoriesSelect from '@components/shared/promotional-categories-select'
import TagsSelect from '@shared/tags-select'
import { createProduct } from '@services/product-service'
import { fetchProductCategories } from '@services/product-categories-service'
import { setFlashMessage } from '@store/reducers/flash-message/actions'

function isValidHex(hex) {
  return (
    typeof hex === 'string' && hex.length === 6 && !isNaN(Number('0x' + hex))
  )
}

const useStyles = makeStyles()((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'row',
  },
  preview: {
    marginLeft: theme.spacing(2),
    padding: theme.spacing(2),
    marginBottom: theme.spacing(3),
  },
  colorDisplay: {
    height: 16,
    width: 16,
    marginBottom: theme.spacing(1),
  },
  submitButton: {
    marginTop: 24,
    backgroundColor: theme.palette.secondary.main,
    color: 'white',
  },
  type: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    width: '100%',
  },
  control: {
    marginBottom: theme.spacing(1),
  },
  imageContainer: {
    width: '100%',
  },
  image: {
    height: 180,
  },
  tags: {
    marginLeft: theme.spacing(1),
  },
}))

const mapStateToProps = (state) => ({
  categories: Object.values(state.ProductCategories),
})

const mapDispatchToProps = (dispatch) => ({
  createProduct: createProduct(dispatch),
  fetchProductCategories: fetchProductCategories(dispatch),
  ...bindActionCreators(
    {
      setFlashMessage,
    },
    dispatch,
  ),
})

function ColorDisplay({ color }) {
  const { classes } = useStyles()
  const isColorValid = isValidHex(color)
  const backgroundColor = isColorValid ? `#${color}` : '#fff'

  return (
    <Box
      boxShadow={1}
      className={classes.colorDisplay}
      style={{ backgroundColor }}
    />
  )
}

ColorDisplay.propTypes = {
  color: PropTypes.string,
}

function ProductForm(props) {
  const { classes, cx } = useStyles()

  const product = props.initialProductData || {}
  const [imageUrl, setImageUrl] = useState(product.image_url)
  const [imageName, setImageName] = useState('')
  const [type, setType] = useState(product.product_type)
  const [price, setPrice] = useState(product.price)
  const [name, setName] = useState(product.name || '')
  const [mobile_menu_format, setMobile_menu_format] = useState(
    product.mobile_menu_format || '',
  )
  const [squareSku, setSquareSku] = useState(product.square_sku || '')
  const [netsuiteAccountId, setNetsuiteAccountId] = useState(
    product.netsuite_account_id || '',
  )
  const [desc, setDesc] = useState(product.description || '')
  const [fgColor, setFgColor] = useState(
    (product.foreground_color || '').stripPrefix('#'),
  )
  const [bgColor, setBgColor] = useState(
    (product.background_color || '').stripPrefix('#'),
  )
  const [tColor, setTColor] = useState(
    (product.tertiary_color || '').stripPrefix('#'),
  )
  const [calories, setCalories] = useState(product.calories || '')
  const [prepTime, setPrepTime] = useState(product.prep_time || '')
  const [isOnDefaultMenu, setIsOnDefaultMenu] = useState(
    product.on_default_menu || false,
  )
  const [inventoryControl, setInventoryControl] = useState(
    product.inventory_control || 'unlimited',
  )
  const [isSeasonal, setIsSeasonal] = useState(product.is_seasonal || false)

  const [didFetchCategories, setDidFetchCategories] = useState(false)

  const [selectedAddons, setSelectedAddons] = useState(product.addons || [])

  const [tags, setTags] = useState(product.tags || [])

  const [promotionalCategories, setPromotionalCategories] = useState(
    product.promotional_categories || [],
  )

  if (!didFetchCategories) {
    props.fetchProductCategories().then(() => setDidFetchCategories(true))
  }

  const getImage = async () => {
    // No image URL => no Image
    if (imageUrl == null || imageUrl === product.image_url) return null

    // Get the base 64 image data
    return new Promise((resolve) => {
      fetch(imageUrl)
        .then((response) => response.blob())
        .then((blob) => {
          const reader = new FileReader()
          reader.readAsDataURL(blob)
          reader.onloadend = () => {
            const base64Data = reader.result
            resolve(base64Data)
          }
        })
    })
  }

  const getProduct = async () => {
    const prepTimeNum = parseInt(prepTime, 10)
    if (prepTime !== '' && isNaN(prepTimeNum)) {
      props.setFlashMessage('Invalid prep time.', 'error')
      return null
    }

    const data = {
      product_type: type,
      name,
      price,
      square_sku: squareSku,
      description: desc,
      calories,
      mobile_menu_format,
      prep_time: prepTimeNum,
      on_default_menu: isOnDefaultMenu,
      product_image_data: null,
      image_filename: null,
      foreground_color: null,
      background_color: null,
      tertiary_color: null,
      addons: selectedAddons,
      inventory_control: inventoryControl,
      is_seasonal: isSeasonal,
      tags,
      netsuite_account_id: netsuiteAccountId,
      promotional_categories: promotionalCategories,
    }

    // Whether or not there's an image name also indicates whether or not a new image
    // was uploaded
    const image = await getImage()
    if (image != null && imageName != null) {
      data.product_image_data = image
      data.image_filename = imageName
    }

    if (!!fgColor && isValidHex(fgColor)) {
      data.foreground_color = '#' + fgColor
    } else if (!fgColor) {
      data.foreground_color = undefined
    }

    if (!!bgColor && isValidHex(bgColor)) {
      data.background_color = '#' + bgColor
    } else if (!bgColor) {
      data.background_color = undefined
    }

    if (!!tColor && isValidHex(tColor)) {
      data.tertiary_color = '#' + tColor
    } else if (!tColor) {
      data.tertiary_color = undefined
    }

    return data
  }

  return (
    <Form title={props.title}>
      <Grid container spacing={4}>
        <Grid item xs={6}>
          <FormImageInput
            imageUrl={imageUrl}
            onChange={(data) => {
              setImageName(data.name)
              setImageUrl(data.url)
            }}
          />
        </Grid>
        <Grid item xs={6}>
          <FormControl className={classes.type} component='fieldset'>
            <FormLabel component='legend'>Product Type</FormLabel>
            <RadioGroup
              aria-label='type'
              name='type'
              onChange={(event) => setType(event.target.value)}
              value={type}
            >
              <FormControlLabel
                control={<Radio />}
                label='Beverage'
                value='beverage'
              />
              <FormControlLabel control={<Radio />} label='Food' value='food' />
              <FormControlLabel control={<Radio />} label='Bag' value='bag' />
              <FormControlLabel
                control={<Radio />}
                label='Bottle'
                value='bottle'
              />
              <FormControlLabel
                control={<Radio />}
                label='Merchandise'
                value='merchandise'
              />
            </RadioGroup>
          </FormControl>
        </Grid>
        <Grid item lg={4} xs={6}>
          <FormInput onChangeText={setName} title='Name' value={name} />
        </Grid>
        <Grid item lg={4} xs={6}>
          <FormInput
            onChangeText={setPrice}
            title='Base Price (in cents)'
            value={price}
          />
        </Grid>
        <Grid item lg={4} xs={6}>
          <ErrorBoundary>
            <FormControl className={cx(classes.tags, classes.control)}>
              <TagsSelect onChange={setTags} selectedTags={tags} />
            </FormControl>
          </ErrorBoundary>
        </Grid>
        <Grid item lg={4} xs={6}>
          <FormInput
            inputProps={{
              disabled: product.square_sku != null,
            }}
            onChangeText={setSquareSku}
            title='SKU'
            value={squareSku}
          />
        </Grid>
        <Grid item lg={4} xs={6}>
          <NetsuiteAccountSelect
            disabled={product.netsuite_account_id != null}
            formControlProps={{ fullWidth: true }}
            onChange={setNetsuiteAccountId}
            value={netsuiteAccountId}
          />
        </Grid>
        <Grid item lg={4} xs={6}>
          <FormInput onChangeText={setDesc} title='Description' value={desc} />
        </Grid>
        <Grid item lg={4} xs={6}>
          <FormInput
            inputProps={{
              startAdornment: (
                <InputAdornment position='start'>#</InputAdornment>
              ),
              endAdornment: <ColorDisplay color={fgColor} />,
            }}
            onChangeText={setFgColor}
            title={'Foreground Color'}
            value={fgColor}
          />
        </Grid>
        <Grid item lg={4} xs={6}>
          <FormInput
            inputProps={{
              startAdornment: (
                <InputAdornment position='start'>#</InputAdornment>
              ),
              endAdornment: <ColorDisplay color={bgColor} />,
            }}
            onChangeText={setBgColor}
            title={'Background Color'}
            value={bgColor}
          />
        </Grid>
        <Grid item lg={4} xs={6}>
          <FormInput
            inputProps={{
              startAdornment: (
                <InputAdornment position='start'>#</InputAdornment>
              ),
              endAdornment: <ColorDisplay color={tColor} />,
            }}
            onChangeText={setTColor}
            title={'Tertiary Color'}
            value={tColor}
          />
        </Grid>
        <Grid item lg={4} xs={6}>
          <FormInput
            inputProps={{
              placeholder: 'e.g. 400-700',
            }}
            onChangeText={setCalories}
            title={'Calories (Optional)'}
            value={calories}
          />
        </Grid>
        <Grid item lg={4} xs={6}>
          <FormInput
            onChangeText={setPrepTime}
            title={'Prep Time (Optional)'}
            value={prepTime}
          />
        </Grid>
        <Grid item lg={4} xs={6}>
          <FormSwitch
            checked={isOnDefaultMenu}
            helperText='Setting this to true will make this product appear in the default menu (when no store is selected)'
            marginTop={16}
            onChange={() => setIsOnDefaultMenu(!isOnDefaultMenu)}
            title='Show on the Default Menu'
          />
        </Grid>
        <Grid item lg={4} xs={6}>
          <InventoryControlSelect
            formControlProps={{ fullWidth: true }}
            onChange={setInventoryControl}
            value={inventoryControl}
          />
        </Grid>
        <Grid item lg={4} xs={6}>
          <FormSwitch
            checked={isSeasonal}
            helperText='Setting this to true will simply flag the product as a seasonal or limited time promotion item.'
            marginBottom={16}
            marginTop={16}
            onChange={() => setIsSeasonal(!isSeasonal)}
            title='Seasonal/Promotional Product'
          />
        </Grid>
        <Grid item lg={4} xs={6}>
          <ErrorBoundary>
            <FormControl className={cx(classes.tags, classes.control)}>
              <PromotionalCategoriesSelect
                onChange={setPromotionalCategories}
                value={promotionalCategories}
              />
              <FormHelperText>
                The helps categorize which groups the product falls into when
                managing promotion exclusions.{' '}
              </FormHelperText>
            </FormControl>
          </ErrorBoundary>
        </Grid>
        <Grid item lg={4} xs={6}>
          <Typography variant='subtitle1'>Addons</Typography>
          <AddonSelector
            selectedAddons={product.addons}
            updateAddons={setSelectedAddons}
          />
          {props.children}
        </Grid>
        <Grid item xs={12}>
          <FormInput
            onChangeText={setMobile_menu_format}
            title='Mobile Menu string'
            value={mobile_menu_format}
          />
        </Grid>
      </Grid>
      <Button
        className={classes.submitButton}
        onClick={async () => {
          const productData = await getProduct()

          if (productData != null) props.onSubmit(productData)
        }}
      >
        Submit
      </Button>
    </Form>
  )
}

ProductForm.propTypes = {
  children: PropTypes.node,
  onSubmit: PropTypes.func,
  fetchProductCategories: PropTypes.func,
  setFlashMessage: PropTypes.func,
  categories: PropTypes.array,
  initialProductData: PropTypes.object,
  title: PropTypes.string,
}

export default connect(mapStateToProps, mapDispatchToProps)(ProductForm)
