import CreateProductStoreDialog from '@components/shared/dialogs/create-product-store-dialog'
import Loader from '@shared/loader'
import MaterialTable from '@material-table/core'
import React, { ReactElement, useMemo, useState } from 'react'
import hasPerms from '@utils/has-permission'
import useAllActions from '@hooks/use-all-actions'
import { getStoreList } from '@queries/stores'
import { useQuery } from '@tanstack/react-query'

interface Props {
  productsStores: any
  product: any
  setStoreVariantPrice(store_id, variant_id, price): Promise<any>
  createProductStore(data): Promise<any>
  removeProductStore(any): Promise<any>
  addToAllStores(): any
}

const ProductsStoresTable = ({
  productsStores,
  createProductStore,
  setStoreVariantPrice,
  removeProductStore,
  product,
  addToAllStores,
}: Props): ReactElement => {
  const [showDialog, setShowDialog] = useState(false)
  const [showDialogLoading, setShowDialogLoading] = useState(false)
  const { setFlashMessage } = useAllActions()

  const { isLoading: loadingStores, data: stores } = useQuery({
    queryKey: ['storeList'],
    queryFn: getStoreList,
    staleTime: 30000,
  })

  // To add products to stores from the product page you need to be assigned to
  // access ALL stores
  const editable =
    hasPerms('stores:update-all') && hasPerms('stores:update-menu')

  const tableData = useMemo(() => {
    const parents = productsStores.map((p) => ({
      id: p.store_id,
      storeId: p.store_id,
      name: stores?.find((store) => store.id === p.store_id)?.name,
      sku: null,
      price: null,
    }))
    return parents
      .concat(
        productsStores.map((p) => {
          return product.variants.map((variant) => ({
            id: `v${variant.id}`,
            name: null,
            variantId: variant.id,
            storeId: p.store_id,
            sku: variant.sku,
            parentId: p.store_id,
            price: p.prices.find((x) => x.variant_id == variant.id).price,
          }))
        }),
      )
      .flat()
  }, [productsStores, stores])

  if (loadingStores) return <Loader />

  return (
    <>
      <CreateProductStoreDialog
        hideProduct
        hideStore={false}
        initialData={{
          productId: product.id,
        }}
        loading={showDialogLoading}
        onClose={() => setShowDialog(false)}
        onConfirm={(data) => {
          setShowDialogLoading(true)
          createProductStore(data)
            .then(() => {
              setShowDialogLoading(false)
              setShowDialog(false)
              setFlashMessage('Added product to store.', 'success')
            })
            .catch((err) => {
              setShowDialogLoading(false)
              setFlashMessage(err.message, 'error')
            })
        }}
        open={showDialog}
      />
      <MaterialTable
        actions={
          editable
            ? [
                {
                  tooltip: 'Add Product to Store',
                  icon: 'add_box',
                  isFreeAction: true,
                  onClick: () => setShowDialog(true),
                },
                {
                  tooltip: 'Add Product to all Stores',
                  icon: 'dynamic_feed',
                  isFreeAction: true,
                  onClick: addToAllStores,
                },
              ]
            : []
        }
        columns={[
          {
            title: 'Store',
            field: 'name',
            editable: 'never',
          },
          {
            title: 'SKU',
            field: 'sku',
            editable: 'never',
          },
          {
            title: 'Price (cents)',
            field: 'price',
          },
        ]}
        data={tableData}
        editable={
          editable
            ? {
                isEditHidden: (x) => !x.parentId,
                isDeleteHidden: (x) => x.parentId,
                onRowUpdate: async ({ price, storeId, variantId }: any) => {
                  // The row update will set it to a string
                  const newPrice = parseInt(price, 10)
                  if (isNaN(newPrice)) {
                    return
                  } else {
                    await setStoreVariantPrice(storeId, variantId, newPrice)
                      .then(() => setFlashMessage('Price updated.', 'success'))
                      .catch((e) => setFlashMessage(e.message, 'error'))
                  }
                },
                onRowDelete: async ({ storeId }: any) => {
                  await removeProductStore(storeId)
                    .then(() => setFlashMessage('Success', 'success'))
                    .catch((e) => setFlashMessage(e.message, 'error'))
                },
              }
            : {}
        }
        options={{
          actionsColumnIndex: 4,
        }}
        parentChildData={(row, rows) => rows.find((a) => a.id === row.parentId)}
        title='Stores'
      />
    </>
  )
}

export default ProductsStoresTable
