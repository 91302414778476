import * as api from '../utils/api'
import {
  UPDATE_PRODUCT_FAILURE,
  UPDATE_PRODUCT_SUCCESS,
} from '@store/action-list'
import { apiAction } from '@store/actions/api'

import assert from 'assert'

import createAction from '@store/actions/create-action'
import { addProducts } from '../store/actions/products'
import {
  removeProductsStores,
  updateProductsStores,
} from '../store/actions/products-stores'

/**
 * @todo update to pass dispatch instead of call dispatch
 */
export const fetchAllProducts = (dispatch) => async () => {
  const {
    data: { products },
  } = await api.GET('/p4/products')
  return Promise.all([dispatch(addProducts(products))])
}

export const fetchProductsStoresForProductId =
  (dispatch) => async (productId) => {
    const { data: productsStores } = await api.GET(
      `/p4/products/${productId}/stores/storeProduct`,
    )
    return dispatch(updateProductsStores(productsStores))
  }

export const createProductStore = (dispatch) => async (data) => {
  const { data: productStore } = await api.POST(
    `/p4/products/${data.product_id}/stores`,
    data,
  )
  return dispatch(updateProductsStores([productStore]))
}

export const removeProductAtStore =
  (dispatch) => async (storeId, productId) => {
    const { data: productStore } = await api.DELETE(
      `/p4/stores/${storeId}/products/${productId}`,
      null,
    )

    return dispatch(removeProductsStores([productStore]))
  }

export const setPriceForVariantAtStore =
  (dispatch) => async (storeId, variantId, price) => {
    const { data: productStore } = await api.PATCH(
      `/p4/stores/${storeId}/variants/${variantId}/price`,
      { price },
    )
    return dispatch(updateProductsStores([productStore]))
  }

export const fetchProductsForStore = (dispatch) => async (storeId) => {
  const { data: products } = await api.GET(`/p4/stores/${storeId}/products`)
  return Promise.all([dispatch(addProducts(products))])
}

/**
 * Fetches a single product from the server, and adds it to redux
 */
export const fetchProduct = (dispatch) => async (id) => {
  console.log(`Fetching product ${id}`)
  const { data: product } = await api.GET(`/p4/products/${id}`)
  return Promise.all([dispatch(addProducts([product]))])
}

export const createProduct = (dispatch) => async (productData) => {
  validateProductData(productData)
  const { data: fullProduct } = await api.POST('/p4/products', productData)
  await dispatch(addProducts([fullProduct]))
  return fullProduct
}

export const softUpdateProduct = (dispatch) => async (id, data) => {
  // const { data: { product } } = await api.PATCH(`/p4/products/${id}`, data)
  return dispatch(addProducts([data]))
}

const updateProductSuccess = (data, response) => (dispatch) => {
  dispatch(addProducts([data]))
  dispatch({
    type: UPDATE_PRODUCT_SUCCESS,
    payload: data,
    response,
  })
}

export const updateProduct = (id, data) =>
  apiAction({
    url: `/p4/products/${id}`,
    method: 'PATCH',
    data,
    actionCallbacks: {
      success: updateProductSuccess,
      failure: createAction(UPDATE_PRODUCT_FAILURE),
    },
  })

const validateProductData = (data) => {
  assert(!!data.product_type, 'Must select a type.')
  assert(!!data.name, 'Must provide a name.')
  assert(!!data.square_sku, 'Must provide a SKU.')
}
